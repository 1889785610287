<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Search -->
    <b-dropdown
      size="sm"
      id="kt_quick_search_toggle"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Search.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTSearchDefault></KTSearchDefault>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Search -->

    <!--begin: Notifications -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
        >
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="/media/svg/icons/Code/Compiling.svg" />
          </span>
          <span class="pulse-ring"></span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Notifications -->

    <!--begin: Quick Actions -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Equalizer.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTDropdownQuickAction></KTDropdownQuickAction>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Quick Actions -->

    <!--begin: My Cart -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="/media/svg/icons/Shopping/Cart3.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-650px">
        <!--<KTDropdownMyCart></KTDropdownMyCart>-->
      </b-dropdown-text>
    </b-dropdown>
    <!--end: My Cart -->

    <!--begin: Quick panel toggle -->
    <KTQuickPanel></KTQuickPanel>
    <!--end: Quick panel toggle -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTSearchDefault from "@/views/layout/extras/dropdown/SearchDefault.vue";
import KTDropdownNotification from "@/views/layout/extras/dropdown/DropdownNotification.vue";
import KTDropdownQuickAction from "@/views/layout/extras/dropdown/DropdownQuickAction.vue";
//import KTDropdownMyCart from "@/views/layout/extras/dropdown/DropdownMyCart.vue";
import KTQuickUser from "@/views/layout/extras/offcanvas/QuickUser.vue";
import KTQuickPanel from "@/views/layout/extras/offcanvas/QuickPanel.vue";

import auth from '../../../core/services/store/auth.module';
import store from '@/core/services/store';
import { UPDATE_PERSONAL_INFO } from '@/core/services/store/profile.module';

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      // languages: i18nService.languages
    };
  },
  components: {
    KTSearchDefault,
    KTDropdownNotification,
    KTDropdownQuickAction,
    //KTDropdownMyCart,
    KTQuickUser,
    KTQuickPanel,
  },
  methods: {
    // onLanguageChanged() {
    //   this.languageFlag = this.languages.find(val => {
    //     return val.lang === i18nService.getActiveLanguage();
    //   }).flag;
    // }
  },
  computed: {
    // getLanguageFlag() {
    //   return this.onLanguageChanged();
    // }
  },
  mounted() {
  const user = auth.getters.currentUser(auth.state);
    store.dispatch(UPDATE_PERSONAL_INFO, {
      name: user.first_name,
      surname: user.last_name,
      email: user.email,
      photo: "/media/users/300_21.jpg",
      company_name: "Fifestudios",
      job: "Application Developer",
      phone: "44(76)34254578",
      company_site: "fifestudios"
    })
  }
};
</script>
