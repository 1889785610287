<template lang="pug">
	ul.menu-nav

		template(v-for="(group, groupIndex) in menuItems")
			li.menu-section(v-if="group.label" :key="groupIndex")
				h4.menu-text {{group.label}}
				i.menu-icon.flaticon-more-v2
			template(v-for="(item, itemIndex) in group.items")
				router-link(v-if="!item.children", :key="groupIndex+'_'+itemIndex", :to="'/app'+item.path", v-slot="{ href, navigate, isActive, isExactActive }")
					li(aria-haspopup="true", data-menu-toggle="hover" class="menu-item",
						:class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]")
						a.menu-link(:href="href", @click="navigate")
							i.menu-icon(:class="item.icon")
							span.menu-text {{ item.label }}

				li.menu-item.menu-item-submenu(v-if="item.children && item.children.length", :key="groupIndex+'_'+itemIndex",
					aria-haspopup="true", data-menu-toggle="hover", :class="{'menu-item-open': hasActiveChildren('/app'+group.path+item.path)}")
					a.menu-link.menu-toggle(href="#")
						i.menu-icon(:class="item.icon")
						span.menu-text {{item.label }}
						i.menu-arrow
					div.menu-submenu.menu-submenu-classic.menu-submenu-right
						ul.menu-subnav
							router-link(v-for="(subItem, subItemIndex) in item.children", :key="subItemIndex",
								:to="'/app'+item.path+subItem.path", v-slot="{ href, navigate, isActive, isExactActive }")
								li.menu-item(aria-haspopup="true", data-menu-toggle="hover",
									:class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active']")
									a.menu-link(:href="href", @click="navigate")
										//i.menu-bullet.menu-bullet-dot
											span
										span.menu-text {{ subItem.label }}
</template>

<script>
export default {
	name: "KTMenu",
	data(){
		return {
			menuItems: [
				{
					label: null,
					items: [
						{
							label: 'Dashboard',
							icon: 'flaticon2-architecture-and-city',
							path: '/'
						},
						{
							label: 'Admins',
							path: '/admins'
						},
						{
							label: 'Advertisers',
							path: '/advertisers'
						},
						{
							label: 'Users',
							path: '/users'
						},
						{
							label: 'Assets',
							path: '/assets'
						},
						{
							label: 'App Campaigns',
							path: '/app-campaigns'
						},
						{
							label: 'Video Campaigns',
							path: '/video-campaigns'
						},
						// {
						// 	label: 'Reports',
						// 	icon: 'flaticon2-expand',
						// 	path: '/reports',
						// 	children: [
						// 		{
						// 			label: 'Reports',
						// 			path: ''
						// 		},
						// 		{
						// 			label: 'Raw data',
						// 			path: '/raw'
						// 		},
						// 	]
						// },
					]
				},
			]
		};
	},
	methods: {
		hasActiveChildren(match){
			return this.$route["path"].indexOf(match) !== -1;
		}
	}
};
</script>
